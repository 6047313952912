/* You can add global styles to this file, and also import other style files */

@import '~@angular/material/theming';

html, body { height: 100%; }
body { margin: 0; font-family: 'RobotoMono', sans-serif; }

.fill-container {
  height: 100%;
  width: 100%;
}

.center-box-outer {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);

  border: 1px solid lightblue
}

.center-box-inner {
  margin: 50px;
}

.mat-icon {
  opacity: .6;
}

mat-form-field {
  width: 100%;
}

.mat-app-background {
  width: 100%;
  height: 100%;
}

.bread-crumbs {
  margin: 5px;
  margin-bottom: 10px;
}

.bread-crumb-button {
  font-size: 1.5em;
  opacity: .8;
}

.bread-crumb-icon {
  margin-top: 14px;
  margin-left: 10px;
  opacity: .6;
  /*height: 5px;*/
  font-size: 10px;
  height: 10px;
  width: 10px;
  /*line-height: 10px;*/
}

.mat-toolbar {
  background-color: white;
  border-bottom: #E0E0E0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  padding-left: 8px;
}

.entry-box {
  min-height: 100%;
  height: auto;
  padding-top: 30px;
  width: 50%;
  margin: 0 auto;
  max-width: 400px;
  padding-bottom: 100px;
}

.entry-box .mat-card {

}

.mobile {
  height: 100%;
}

.mobile .entry-box {
  height: 100%;
  width: 100%;
  margin-top: 0;
  padding: 0;
  max-width: 3000px;
}

.mobile .entry-box .mat-card {
  width: 100%;
  height: 100%;
  margin-top: 0;
  padding: 0;
}

.entry-box .logo-box {
  max-width: 250px;
}

.entry-box .logo {
  width: 100%;
}

.entry-box h2 {
  border-bottom: lightgray;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  opacity: .6;
  padding-top: 0;
  margin-top: 0;
  padding-bottom: 20px;
  margin-bottom: 20px;
  line-height: 2px;
}

.logo-box h2 {
  text-align: right;
}

.entry-box .mat-stroked-button {
  margin-top: 5px;
}

.entry-box .mat-card-content {
  padding: 20px;
  margin-bottom: 0;
}

.progress-bar {
  height: 2px;
}

::ng-deep .mat-form-field-underline {
  display: none;
}


.form-reject {
  width: 100%;
  padding-top: 10px;
}

//.child-detail-form {
//  max-width: 350px;
//  padding-left: 20px;
//  padding-right: 20px;
//}

.child-details {
  overflow-y: scroll;
  -ms-overflow-style: none;
  height: 100%;
  width: 100%;
  padding: 20px;
}

.child-meta-details {
  //padding: 20px;
}

html {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.children {
  height: 100%;
  min-width: 50%;
}

.child-details-container {
  border-left-color: lightgray;
  border-left-style: solid;
  border-left-width: 1px;
}


.list-detail {
  width: 100%;
  height: 100%;
}

.list-detail-content {
  width: 100%;
  height: 100%;
}

.list-detail-list {
  width: 100%;
  max-width: 100%;
  min-width: 50%;
  height: 100%;
  //overflow-y: scroll;
  //-ms-overflow-style: none;
}

.list-detail-detail {
  width: 100%;
  max-width: 100%;
  min-width: 50%;
  height: 100%;
  border-left-color: lightgray;
  border-left-style: solid;
  border-left-width: 1px;
  //overflow-y: auto;
  //-ms-overflow-style: none;
}

.list-detail-detail-body {
  height: 100%;
  padding: 20px;
  overflow-y: auto;
  -ms-overflow-style: none;
}

.list-detail h2 {
  padding-right: 15px;
}


.signature {
  height: 50px;
  width: 100%;
}
